
import {defineComponent} from 'vue'
import Row from "@/components/base/common/Row.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import CallAuditForm from "@/views/activity/CallAuditForm.vue";
import {Mutations} from "@/store/enums/StoreEnums";
import store from "@/store";
import CallAuditStatus from "@/views/activity/CallAuditStatus.vue";
import Avatar from "@/components/base/common/Avatar.vue";

export default defineComponent({
  name: "CallAudit",
  components: {Avatar, CallAuditStatus, CallAuditForm, BaseModal, QuickAction, Row},
  props: {
    activity: {},
  },
  setup() {

  },
  methods:{
    addAudit() {
      const modal = this.$refs.auditCallRef  as typeof BaseModal;
      modal.showBaseModal();
    },
    onSave(res) {
      store.commit(Mutations.SET_ACTIVITY, res)
      const modal = this.$refs.auditCallRef  as typeof BaseModal;
      modal.hideBaseModal();
    }
  }
})
