
import QuickAction from "@//components/base/action/QuickAction.vue";
import { defineComponent, ref } from "vue";
import Avatar from "@/components/base/common/Avatar.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import DocumentService from "@/core/services/DocumentService";
import { LoadPanel } from "@/core/composite/composite";

export default defineComponent({
  name: "AudioFile",
  components: {DateTimeFormat, Avatar, QuickAction},
  props: {
    document: {type: Object}
  },
  setup() {
    const play = ref({isPlay: false, link: ''})
    return {
      play,
      ...LoadPanel(),
    }
  },
  methods: {
    deletedDoc(id) {
      this.$emit("delete", id)
    },
    onDownloadPdf(id) {
      this.$emit("download", id)
    },
    showDetail(id) {
      this.$emit("showDetail", id)
    },
    onShow(id) {
      this.$emit("panel", id)
    },

    onPlay(id) {
      DocumentService.getUrl(id).then(res=>{
        this.play.link = res.link;
        this.play.isPlay = true
      })
    },
    onStop() {
      this.play.isPlay = false;
    }
  }
})
