
import {computed, defineComponent} from 'vue'
import store from "@/store";
import {LoadEntity} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import EntityView from "@/components/base/common/EntityView.vue";
import TimeSpent from "@/views/activity/TimeSpent.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import {onUpdated} from "@vue/runtime-core";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import ActivityDocument from "@/views/activity/ActivityDocument.vue";
import Notes from "@/views/note/Notes.vue";
import Audits from "@/views/audit/Audits.vue";
import CaseData from "@/views/activity/CaseData.vue";
import ClaimData from "@/views/activity/ClaimData.vue";
import Transcribe from "@/views/activity/Transcribe.vue";
import CallAnalysis from "@/views/activity/CallAnalysis.vue";
import CallAudit from "@/views/activity/CallAudit.vue";

export default defineComponent({
  name: "PhoneCallPage",
  components: {
    CallAudit,
    CallAnalysis,
    Transcribe,
    ClaimData,
    CaseData,
    Audits, Notes, ActivityDocument,
    Avatar,
    TimeSpent,
    EntityView,
    DateTimeFormat,
    EntityLoading
  },
  setup() {
    onUpdated(() => {
      setCurrentPageBreadcrumbsWithParams('Phone Call Details', [
        {link: false, router: '', text: 'View Phone call'}
      ])
    })
    const entity = computed(() => store.state.ActivityModule.activity);
    const entityLoading = computed(() => store.state.LoadingModule.entityLoading);
    return {
      entityLoading,
      entity,
      ...LoadEntity(Actions.LOAD_ACTIVITY_DETAIL)
    }
  }
})
