
import {defineComponent, ref} from 'vue'
import ClaimService from "@/core/services/ClaimService";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import ProductLink from "@/views/product/ProductLink.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import DecodeId from "@/components/base/DecodeId.vue";
import Row from "@/components/base/common/Row.vue";
import {LoadPanel} from "@/core/composite/composite";
import ClaimLink from "@/views/claim/ClaimLink.vue";

export default defineComponent({
  name: "ClaimData",
  components: {ClaimLink, Row, DecodeId, DateTimeFormat, ProductLink, EntityLoading},
  props: {
    id: {type: String, required: true},
  },
  setup(props) {
    const loading = ref(false);
    const claim = ref<any>({});
    const load = (id) => {
      loading.value = true;
      ClaimService.get(id).then(res => {
        claim.value = res;
      }).finally(() => {
        loading.value = false
      })
    }
    load(props.id)
    return {
      claim,
      loading,
      ...LoadPanel(),
    }
  }
})
