
import { computed, defineComponent, ref } from "vue";
import { LoadFilterObjects } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import Cards from "@/components/base/common/Cards.vue";
import store from "@/store";
import DocumentService from "@/core/services/DocumentService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import AudioFile from "@/views/document/AudioCard.vue";

export default defineComponent({
  name: "ActivityDocument",
  components: {AudioFile,  Cards},
  props: {
    activity: {type: Object},
    action: {default:true}
  },
  setup(props) {

    const filterObject = ref({activityId: props?.activity?.id})
    const activityDocumentPage = computed(() => store.state.DocumentModule.activityDocumentPage)
    return {
      ...LoadFilterObjects(Actions.LOAD_ACTIVITY_DOCUMENTS, filterObject.value, ['createdBy']),
      activityDocumentPage,
      filterObject,
    }
  },
  methods: {
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await DocumentService.delete(id).then(() => {
            this.paginationLoad()
          })
        }
      })
    },
    onDownloadPdf(id) {

      DocumentService.download(id).then(res => {
        let fileName = res.headers["content-disposition"].split("filename=")[1];
        let blob = new Blob(
          [res.data]
        );
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
    },
    reload() {
      this.paginationLoad()
    }

  },
  watch: {
    activity(value) {
      this.filterObject.activityId = value.id
      this.updateFilterObject(this.filterObject);
    }
  }
})
