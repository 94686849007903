
import {defineComponent, ref, watch} from 'vue'
import BaseForm from "@/components/base/form/BaseForm.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import Froala from "@/components/base/editor/Froala.vue";
import ActivityService from "@/core/services/ActivityService";

export default defineComponent({
  name: "CallAuditForm",
  components: {Froala, BaseRadio, BaseForm},
  props: {
    id: {},
    audit: {}
  },
  emits: ['saved'],
  setup(props) {
    const model = ref<any>(props.audit);
    watch(() => props.audit, cb => {
      model.value = cb;
    })
    const submitting = ref(false);
    return {
      submitting,
      model,
    }
  },
  methods: {
    formSubmit() {
      this.submitting = true;
      ActivityService.audit(this.id, this.model).then(res=>{
        this.$emit('saved', res)
      }).finally(()=>{
        this.submitting = false;
      })
    }
  }
})
