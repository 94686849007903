
import {defineComponent, ref} from 'vue'

export default defineComponent({
  name: "TimeSpent",
  props: {
    time: {required: true, type: Number}
  },
  setup(props) {
    const
      format = ref('00:00');
    const build = (time: number) => {
      const hours = Math.floor(time / 60);
      const minutes = time % 60;
      format.value = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }
    build(props.time)
    return {
      format,
    }
  }
})
