
import {defineComponent, ref, watch} from 'vue'

export default defineComponent({
  name: "CallAuditStatus",
  props: {
    status: {}
  },
  setup(props) {
    const data = ref({name: '', clazz: ''})
    const build = (status) => {
      if (!status) {
        data.value = {name: '', clazz: ''}
      }
      switch (status) {
        case 'PENDING':
          data.value = {name: 'Pending', clazz: 'secondary'}
          break

        case 'COMPLETE':
          data.value = {name: 'Completed', clazz: 'success'}
          break
      }
    }
    build(props.status)
    watch(() => props.status, (cb) => {
      build(cb)
    })
    return {
      data
    }
  }
})

