import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AudioFile = _resolveComponent("AudioFile")!
  const _component_Cards = _resolveComponent("Cards")!

  return (_ctx.activityDocumentPage.total > 0)
    ? (_openBlock(), _createBlock(_component_Cards, {
        key: 0,
        total: _ctx.activityDocumentPage.total,
        "card-data": _ctx.activityDocumentPage.data,
        "current-page": _ctx.filter.paginationPage,
        "rows-per-page": _ctx.filter.paginationSize,
        onCurrentChange: _ctx.handleUpdatePage,
        stretched: ""
      }, {
        "card-info": _withCtx(({card: document}) => [
          _createVNode(_component_AudioFile, {
            document: document,
            onDownload: _ctx.onDownloadPdf,
            onDelete: _ctx.onDelete
          }, null, 8, ["document", "onDownload", "onDelete"])
        ]),
        _: 1
      }, 8, ["total", "card-data", "current-page", "rows-per-page", "onCurrentChange"]))
    : _createCommentVNode("", true)
}