
import {defineComponent, ref} from 'vue'
import CaseService from "@/core/services/CaseService";
import ProductLink from "@/views/product/ProductLink.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import DecodeId from "@/components/base/DecodeId.vue";
import Row from "@/components/base/common/Row.vue";
import {LoadPanel} from "@/core/composite/composite";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import CaseLink from "@/views/case/CaseLink.vue";

export default defineComponent({
  name: "CaseData",
  components: {CaseLink, EntityLoading, Row, DecodeId, DateTimeFormat, ProductLink},
  props: {
    id: {type: String, required: true},
  },
  setup(props) {
    const loading = ref(false);
    const caze = ref<any>({});
    const loadData = (id) => {
      loading.value = true;
      CaseService.get(id).then(res => {
        caze.value = res;
      }).finally(() => {
        loading.value = false;
      })
    }
    loadData(props.id)
    return {
      loading,
      ...LoadPanel(),
      caze,
    }
  }
})
