
import {defineComponent} from 'vue'

export default defineComponent({
  name: "AlParts",
  props: {
    data: {}
  },

  methods: {
    formatString(str) {
      // Remove underscores
      const noUnderscores = str.replace(/_/g, ' ');
      // Capitalize the first letter
      return noUnderscores.charAt(0).toUpperCase() + noUnderscores.slice(1);
    }

  }
})
